<template>
    <b-modal id="success-modal" size="md" hide-footer centered hide-header @hidden="closed">
        <!-- Post One click Upgrade -->
        <div class="success-step py-4">
            <div class="row justify-content-center">
                <div class="col-md-11 px-0">
                    <div v-if="subscription.on_grace_period">
                        <div class="text-center">
                            <h4 class="font-weight-bold mb-2">Plan Cancelled</h4>
                            <p class="f-14 text-center mt-4">
                                {{
                                    `Your ${subscription.name} plan has been canceled and will not be renewed. You can continue to use all ${subscription.name} features until the end of the Grace Period. `
                                }}
                            </p>

                            <p class="w-75 mx-auto f-13 py-3 bg-tip rounded-lg text-center mt-4">
                                You will not be charged again unless you start a new plan.
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <div class="text-center">
                            <h4 class="font-weight-bold mb-2">Success!🎉</h4>
                            <p class="f-14 text-center mt-4">
                                {{
                                    one_click_upgrade.success_feedback.length
                                        ? one_click_upgrade.success_feedback
                                        : `Your plan has been updated and will begin automatically when your ${user.user.onGenericTrial || subscription.on_trial ? 'trial' : 'current subscription'} ends. You can continue to use all features, but won’t be charged until your next scheduled billing date.`
                                }}
                            </p>

                            <p class="w-75 mx-auto f-13 py-3 bg-tip rounded-lg text-center mt-4">
                                You will be charged
                                <b>${{ subscriptionAmount(Math.abs(current_plan.amount_in_cents) / 100) }}</b>
                                on
                                <b>{{ subscription.current_period_end }}</b>
                                .
                                <br />
                                Renews
                                {{ subscription.one_click_content.plan_frequency === 'yearly' ? 'annually' : 'monthly' }}.
                                Cancel anytime.
                            </p>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-success text-white mt-4 py-1 px-4" @click="$bvModal.hide('success-modal')">
                            Got it{{ one_click_upgrade.success_feedback.length ? ', thanks!' : '!' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import Common from '../mixins/Common'

export default defineComponent({
    name: 'OneClickSuccessModal',
    mixins: [Common],
    props: {
        brand: {
            type: String,
            default: 'worksheet',
        },
    },
    computed: {
        ...mapState(['subscription', 'user']),
        ...mapGetters({
            one_click_upgrade: 'subscription/oneClickUpgrade',
            publishing: 'document/onpublishing',
        }),
        current_plan() {
            return this.getPlan(this.subscription.one_click_content.plan_category) ?? {}
        },
    },
    methods: {
        closed() {
            this.$bvModal.hide('success-modal')
            if (this.publishing) this.$bvModal.show('printable-modal')
        },
    },
})
</script>

<style scoped>
.bg-tip {
    background: #d1ecf1;
    color: #024b62;
}
</style>
