<template>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div v-if="plan">
                <div v-if="planLabel === 'lifetime'">
                    <p class="font-weight-bold text-center mb-0">Lifetime Premium Membership</p>
                    <p class="f-14 text-muted text-center">
                        Unlock premium membership on all our sister sites -
                        <strong>For life!</strong>
                        The absolute best value for educators. Create unlimited
                        <a target="_blank" rel="noopener noreferrer" :href="crossword_url">crosswords</a>
                        ,
                        <a target="_blank" rel="noopener noreferrer" :href="wordsearch_url">word searches</a>
                        , worksheets, bingo and flashcards.
                    </p>
                </div>

                <div v-if="planLabel === 'Standard'">
                    <p class="font-weight-bold text-center mb-0">Standard Membership</p>

                    <div v-if="brandConfig.alias === 'worksheet'">
                        <p class="f-14 text-muted text-center">
                            Unlock every single option, style, color, font and formatting choice for fully customizable
                            worksheets.
                        </p>
                    </div>

                    <div v-if="brandConfig.alias === 'flashcard' || brandConfig.alias === 'bingo'">
                        <p class="f-14 text-muted text-center">
                            Make unlimited Bingo and Flashcard set with all features unlocked. Your Bingo and Flashcards
                            automatically save as you go and are available on your account forever. Awesome!
                        </p>
                    </div>
                </div>

                <div v-if="planLabel === 'Premium'">
                    <p class="font-weight-bold text-center mb-0">Premium Membership</p>
                    <p class="f-14 text-muted text-center">
                        The absolute best value for educators. Create unlimited worksheets,
                        <a target="_blank" rel="noopener noreferrer" :href="crossword_url">crosswords</a>
                        ,
                        <a target="_blank" rel="noopener noreferrer" :href="wordsearch_url">word searches</a>
                        , bingo and flashcards.
                    </p>
                </div>
            </div>

            <div class="text-center">
                <b-button
                    :disabled="isLoadingCheckout"
                    :variant="isLoadingCheckout ? 'secondary' : 'success'"
                    class="px-3"
                    @click="upgradePlan()"
                >
                    <Loader v-show="isLoadingCheckout"></Loader>
                    <span :class="isLoadingCheckout ? 'saving' : ''">Upgrade</span>
                </b-button>

                <p class="font-weight-bold mb-1 mt-3">
                    We offer a 100% satisfaction guarantee
                    <svg
                        height="20"
                        weight="20"
                        class="text-success mr-1 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                </p>

                <p class="text-muted f-13">
                    See our
                    <a class="domain-text-color" target="_blank" rel="noopener noreferrer" href="/payments-and-refunds">
                        full payment and refund policies.
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import WithCheckout from '../../mixins/WithCheckout'

export default defineComponent({
    name: 'PlanUpgrade',
    mixins: [WithCheckout],
    methods: {
        async upgradePlan() {
            if (this.isLoadingCheckout) {
                return
            }

            this.setIsLoadingCheckout(true)

            this.logPaymentSubmittion(this.plan?.interval, 'buy now', this.planLabel?.toLowerCase(), this.page)

            try {
                await this.$store.dispatch('subscription/changeFrequency', { plan: this.plan.id })

                this.logPaymentSuccess(this.plan?.interval, 'buy now', this.planLabel?.toLowerCase(), this.page)
                this.$emit('active-step', 'confetti')
                this.getUser()
            } catch (error) {
                this.setIntent(null)
                this.setSubscriptionError('There was an error upgrading your plan.')
            } finally {
                this.setIsLoadingCheckout(false)
            }
        },
    },
})
</script>
