<template>
    <div class="row justify-content-center">
        <div class="col-md-10">
            <PaymentConfirmationDetails :page="page" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import WithCheckout from '../../mixins/WithCheckout'
import PaymentConfirmationDetails from './PaymentConfirmationDetails.vue'

export default defineComponent({
    components: {
        PaymentConfirmationDetails,
    },
    mixins: [WithCheckout],
})
</script>
