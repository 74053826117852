<template>
    <div v-if="selectedPlan">
        <div class="text-center mb-4">
            <h2 v-if="selectedPlan !== 'single'" class="font-weight-bold mb-2">{{ planDetails[selectedPlan].title }}</h2>
            <h2 v-else class="font-weight-bold mb-2">{{ documentType }} Ahoy!!</h2>
            <p class="font-weight-bold text-center" v-html="subtitle" />
        </div>

        <p class="f-14" v-html="text" />

        <p v-if="planDetails[selectedPlan].showTips" class="f-14">Here are some tips to get you started:</p>

        <ul class="f-13">
            <li
                v-for="(item, index) in planDetails[selectedPlan].customBulletItems"
                :key="index"
                class="mb-1"
                v-html="item"
            />

            <li v-if="planDetails[selectedPlan].showPuzzles" class="mb-1">
                To access
                <a target="_blank" rel="noopener noreferrer" :href="crossword_url">My Crossword Maker</a>
                and
                <a target="_blank" rel="noopener noreferrer" :href="wordsearch_url">My Word Search</a>
                , simply visit each site and create an account with the same email you used on My Worksheet Maker.
            </li>

            <li v-if="planDetails[selectedPlan].showMyProfile" class="mb-1">
                Click on
                <a target="_blank" rel="noopener noreferrer" :href="applicationLinks.myProfile">My Account</a>
                to access your
                {{
                    selectedPlan === 'lifetime'
                        ? "receipt. With Lifetime Premium, you'll never be charged again."
                        : 'receipt, see your renewal date and manage your subscription.'
                }}
            </li>
            <li v-if="planDetails[selectedPlan].showSisters" class="mb-1">
                <strong>
                    To access all
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://myworksheetmaker.groovehq.com/help/what-are-your-other-sites-do-you-have-other-builders-for-teachers"
                    >
                        sister sites
                    </a>
                </strong>
                , simply visit the site’s homepage and create a new account with the same email address you used on My
                Worksheet Maker.
            </li>

            <li v-if="selectedPlan === 'Basic' && brand === 'worksheet'" class="mb-1">
                To save your worksheets as
                <strong>'Private'</strong>
                , simply switch the Privacy Settings toggle on the publish screen. Only you can see private worksheets.
            </li>

            <li v-if="planDetails[selectedPlan].showSocial" class="mb-1">
                Check your email for additional tips and helpful reminders. Make sure to follow us on
                <a target="_blank" rel="noopener noreferrer" :href="socialLinks.facebook">Facebook</a>
                and
                <a target="_blank" rel="noopener noreferrer" :href="socialLinks.twitter">Twitter</a>
                .
            </li>

            <li>
                <strong>Need a hand?</strong>
                Visit our
                <a target="_blank" rel="noopener noreferrer" :href="applicationLinks.faq">FAQs</a>
                or
                <ContactSupportLink />
                . We're here to help.
            </li>
        </ul>
        <div class="text-center">
            <button class="btn btn-sm btn-success text-white my-2" @click="$bvModal.hide('checkout-modal')">Got it!</button>
            <p class="my-2 text-muted f-12 text-center">
                🍎
                <i>Thank you for your support of educators around the world!</i>
                🍎
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ApplicationLink, SocialLink } from '../../config/Config'
import WithCheckout from '../../mixins/WithCheckout'
import ContactSupportLink from '../ContactSupportLink.vue'

interface PlanDetail {
    [key: string]: {
        title: string
        subtitle: string | { [key: string]: string }
        text?: string | { [key: string]: string }
        customBulletItems?: string[]
        showTips: boolean
        showSisters: boolean
        showPuzzles: boolean
        showMyProfile: boolean
        showSocial: boolean
    }
}

export default defineComponent({
    components: {
        ContactSupportLink,
    },
    mixins: [WithCheckout],
    data() {
        return {
            planDetails: {
                Premium: {
                    title: 'Welcome Aboard!',
                    subtitle: '<i>Premium Membership</i> unlocked. Hooray!',
                    text: 'Your membership is now active! You have <strong>full, premium access</strong> to all <a target="_blank" rel="noopener noreferrer" :href="applicationLinks.sisterSites">sister sites</a> and every single option and feature.',
                    showTips: true,
                    showSisters: true,
                    showPuzzles: true,
                    showMyProfile: true,
                    showSocial: true,
                },
                freeTrialPremium: {
                    title: 'Welcome Aboard!',
                    subtitle: 'Your 30-day free trial starts today. Hooray!',
                    text: '',
                    customBulletItems: [],
                    showTips: false,
                    showSisters: false,
                    showPuzzles: false,
                    showMyProfile: false,
                    showSocial: true,
                },
                Standard: {
                    title: 'Welcome Aboard!',
                    subtitle: {
                        worksheet: 'Standard membership unlocked. Hooray!',
                        bingo: 'Unlimited bingo and flashcard sets unlocked. Hooray!',
                        flashcards: 'Unlimited flashcard and bingo sets unlocked. Hooray!',
                    },
                    text: {
                        worksheet:
                            "You now have access to all My Worksheet Maker's premium features and options. We can't wait to see what you create!",
                        bingo: 'You now have access to make as many bingo and flashcard as you want. We can’t wait to see what you create!',
                        flashcards:
                            'You now have access to make as many flashcards and bingo as you want. We can’t wait to see what you create!',
                    },
                    showTips: true,
                    showSisters: false,
                    showPuzzles: false,
                    showMyProfile: true,
                    showSocial: true,
                },
                Basic: {
                    title: 'Welcome Aboard!',
                    subtitle: {
                        worksheet: 'Basic membership unlocked. Hooray!',
                        bingo: 'Unlimited bingo sets unlocked. Hooray!',
                        flashcards: 'Unlimited flashcard sets unlocked. Hooray!',
                    },
                    text: '',
                    showTips: false,
                    showSisters: false,
                    showPuzzles: false,
                    showMyProfile: true,
                    showSocial: true,
                },
                lifetime: {
                    title: 'Welcome Aboard!',
                    subtitle: '👑 Lifetime Premium access unlocked. Hooray! 👑',
                    text: 'Your <i>Lifetime Premium Membership</i> is now active and we are so thrilled you’ve joined us for the long haul! Welcome to the family. 😎',
                    showTips: true,
                    showSisters: true,
                    showPuzzles: false,
                    showMyProfile: true,
                    showSocial: true,
                },
                single: {
                    title: 'Welcome Aboard!',
                    subtitle: 'Purchase complete. Thank you for your payment!',
                    text: '',
                    customBulletItems: [],
                    showTips: false,
                    showSisters: false,
                    showPuzzles: false,
                    showMyProfile: false,
                    showSocial: true,
                },
                freeTrial: {
                    title: 'Welcome Aboard!',
                    subtitle: 'Your 30-day free trial starts today. Hooray!',
                    text: '',
                    customBulletItems: [],
                    showTips: false,
                    showSisters: false,
                    showPuzzles: false,
                    showMyProfile: false,
                    showSocial: true,
                },
            } as PlanDetail,
        }
    },
    computed: {
        brand(): string {
            if (this.page === 'account') {
                return 'worksheet'
            }
            return this.page
        },
        socialLinks(): Record<SocialLink, string> {
            return this.brandConfig.socialLinks
        },
        applicationLinks(): Record<ApplicationLink, string> {
            return this.brandConfig.applicationLinks
        },
        subtitle(): string {
            const plan = this.planDetails[this.selectedPlan]
            if (typeof plan.subtitle === 'string') {
                return plan.subtitle
            }
            return plan.subtitle[this.brand]
        },
        text(): string {
            const plan = this.planDetails[this.selectedPlan]
            if (!plan.text) return ''

            if (typeof plan.text === 'string') {
                return plan.text
            }

            return plan.text[this.brand]
        },
        documentType(): string {
            const response = `${this.brand.charAt(0).toUpperCase() + this.brand.slice(1)}`

            switch (this.brand) {
                case 'bingo':
                    return `${response},`
                case 'flashcard':
                    return `${response}s`
                default:
                    return response
            }
        },
        selectedPlan(): string {
            if (!this.freeTrialPeriod) return this.planLabel

            return this.planLabel === 'Premium' ? `freeTrial${this.planLabel}` : 'freeTrial'
        },
    },
    created() {
        this.updateCustomBulletItems()
    },
    methods: {
        updateCustomBulletItems() {
            this.planDetails.single.customBulletItems = [
                `Click on <a href="${this.applicationLinks.myProfile}" target="_blank" rel="noopener noreferrer">My Account</a> to view your receipt and explore membership options.`,
                `You can now publish, download, or print your ${this.brand}. Hooray!`,
                `Accidentally made a typo? No problem! This purchase includes <strong>one edit.</strong> Simply unpublish your ${this.brand}, make your changes and then re-publish.`,
                `Visit our <a target="_blank" rel="noopener noreferrer" href="${this.applicationLinks.sisterSites}"> sister sites</a> to discover what else you can build.`,
            ]

            this.planDetails.freeTrialPremium.customBulletItems = [
                `You now have full access to My Worksheet Maker and <strong> <a target="_blank" rel="noopener noreferrer" href="${this.applicationLinks.sisterSites}">all BrightSprout builders! </a> </strong>Simply hover on the "Create" menu on the header to make unlimited activities.`,
                `At the end of your trial, your paid membership will activate.`,
                `Click on <a target="_blank" rel="noopener noreferrer" href="${this.applicationLinks.myProfile}">My Account</a> to view your next charge date and manage membership.`,
                `If you do not wish to continue your subscription, simply cancel before your renewal date to avoid being automatically charged. See our complete payments and refunds <a target="_blank" rel="noopener noreferrer" href="/payments-and-refunds">policy</a> for more information.`,
            ]

            this.planDetails.freeTrial.customBulletItems = [
                `As a special thank you, we’ve <strong>granted you full access to <a target="_blank" rel="noopener noreferrer" href="${this.applicationLinks.sisterSites}">all BrightSprout builders </a> </strong> for 30 days! Simply hover on the "Create" menu on the header to make unlimited, free activities during your trial.`,
                ` At the end of your trial, your subscription will return to your selected plan.`,
                `Click on <a target="_blank" rel="noopener noreferrer" href="${this.applicationLinks.myProfile}">My Account</a> to view your next charge date and manage membership.`,
                `If you do not wish to continue your subscription, simply cancel before your renewal date to avoid being automatically charged. See our complete payments and refunds <a target="_blank" rel="noopener noreferrer" href="/payments-and-refunds">policy</a> for more information.`,
            ]
        },
    },
})
</script>
