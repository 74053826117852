import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import UserApi from '../apis/UserApi'
import { appModule, checkoutModule } from '../utils/store-accessor'
import StatsigHelper from './StatsigHelper'
import { PlanType } from '../objects/Plan'

interface Data {
    subscriptionCycleOptions: any[]
}

export default defineComponent({
    name: 'WithCheckout',
    mixins: [StatsigHelper],
    props: {
        page: {
            type: String,
            default: 'account',
            required: false,
        },
    },
    data(): Data {
        return {
            subscriptionCycleOptions: [
                { text: 'Annual', value: 'yearly' },
                { text: 'Monthly', value: 'monthly' },
                { text: 'Lifetime', value: 'lifetime' },
            ],
        }
    },
    computed: {
        user(): any {
            return this.$store.state.user as any
        },
        document(): any {
            return this.$store.state.document as any
        },
        subscription(): any {
            return this.$store.state.subscription as any
        },
        ...mapGetters({
            onboarding: 'document/onboarding',
            freePlanLayout: 'abtests/freePlanLayout',
            goPremium: 'document/goPremium',
        }),
        brandConfig() {
            // This is required to show the 'default' subscription plan options on the 'My Profile' page etc.
            return this.page === 'account' ? appModule.defaultBrandConfig : appModule.brandConfig
        },
        plan(): any {
            return checkoutModule.selectedPlan
        },
        isLoadingCheckout(): boolean {
            return checkoutModule.isLoading
        },
        freeTrialPeriod(): number {
            return checkoutModule.freeTrial
        },
        subscriptionError(): string | null {
            return checkoutModule.subscriptionError
        },
        processingError(): string | null {
            return checkoutModule.processingError
        },
        subscriptionCycle(): string {
            return checkoutModule.subscriptionCycle
        },
        intent(): any {
            return checkoutModule.intent
        },
        selectedPlanType(): PlanType | undefined {
            return checkoutModule.selectedPlanType
        },
        planLabel() {
            if (!this.plan) {
                return ''
            } else if (this.plan.name === 'Single Document') {
                return 'single'
            } else if (this.plan.name === 'Lifetime Premium Membership') {
                return 'lifetime'
            } else if (
                [
                    'Unlimited Bingo - Yearly',
                    'Unlimited Bingo - Monthly',
                    'Unlimited Flashcards - Yearly',
                    'Unlimited Flashcards - Monthly',
                    'Private Worksheets - Yearly',
                    'Private Worksheets - Monthly',
                ].includes(this.plan.name)
            ) {
                return 'Basic'
            } else if (
                [
                    'Unlimited Bingo and Flashcards - Monthly',
                    'Unlimited Bingo and Flashcards - Yearly',
                    'Unlimited Worksheets - Monthly',
                    'Unlimited Worksheets - Yearly',
                ].includes(this.plan.name)
            ) {
                return 'Standard'
            } else if (['Premium Monthly', 'Premium Yearly'].includes(this.plan.name)) {
                return 'Premium'
            }
            return ''
        },
        brandHasSinglePlan(): boolean {
            return ['worksheet', 'bingo', 'flashcard'].includes(this.page)
        },
        oneClickUpgrade: {
            get() {
                return this.$store.state.subscription.one_click_upgrade
            },
            async set(value: object) {
                await this.$store.dispatch('subscription/setOneClickUpgrade', value)
            },
        },
        crossword_url(): string {
            return window.crossword_url ?? ''
        },
        wordsearch_url(): string {
            return window.wordsearch_url ?? ''
        },
    },
    methods: {
        setIsLoadingCheckout(loading: boolean) {
            checkoutModule.setIsLoading(loading)
        },
        setFreeTrialPeriod(trial: number) {
            checkoutModule.setFreeTrialPeriod(trial)
        },
        setSelectedPlan(plan: any) {
            checkoutModule.setSelectedPlan(plan)
        },
        setSubscriptionError(error: string | null) {
            checkoutModule.setSubscriptionError(error)
        },
        setProcessingError(error: string | null) {
            checkoutModule.setProcessingError(error)
        },
        setSubscriptionCycle(cycle: string) {
            checkoutModule.setSubscriptionCycle(cycle)
        },
        setIntent(intent: any) {
            checkoutModule.setIntent(intent)
        },
        setSelectedPlanType(planType: PlanType) {
            checkoutModule.setSelectedPlanType(planType)
        },
        subscriptionAmount(amount: number): string | number {
            if (amount?.toString().includes('.')) {
                return amount?.toString().split('.')[1] === '00' ? amount.toString().split('.')[0] : amount
            }
            return amount
        },
        async subscribe(plan: any, planType?: PlanType) {
            if (plan.name === 'Lifetime Premium Membership') {
                plan.interval = 'lifetime'
            }

            await this.$store.dispatch('cards/obtainIntent')

            this.$emit('active-step', 'payment')
            this.setSelectedPlan(plan)

            if (planType) {
                this.setSelectedPlanType(planType)
            }

            this.setFreeTrialPeriod(0)

            await this.logChoosePlan(this.plan?.interval, 'buy now', this.planLabel?.toLowerCase(), this.page)
            await this.$eventApi.create_event(`buy-now-${plan.interval}-${plan.id}`, `${plan.name} | Price: ${plan.amount}`)
        },
        async getUser(lambda = null) {
            const id = this.user.user.id

            if (this.brandHasSinglePlan && this.document?.id > 0 && !this.goPremium) {
                await this.$store.dispatch('document/setDocument', { user_id: this.user.user.id })
            }

            return UserApi.get(id)
                .then((record) => {
                    this.$store.dispatch('user/setUser', record.data)
                    if (lambda && this.user.user.id) {
                        lambda()
                    }
                })
                .catch((error) => {
                    return error
                })
        },
    },
})
