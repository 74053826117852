<template>
    <div>
        <div v-show="oneClickUpgrade.subscriptionCycle !== 'lifetime'" class="text-center mb-2 mt-3">
            <FreeTrialCta
                v-if="canTrial && freePlanLayout === 'top-center-section'"
                :single="brandHasSinglePlan"
                :show-single-price="showSinglePrice"
                :plan-id="subscription.plans.premium.monthly.id"
                variant="top-center-section"
                :page="brandConfig.alias"
                @initiatedCardlessTrial="getUser"
            ></FreeTrialCta>

            <h2 class="font-weight-bold mb-2">Pricing Plan Options</h2>

            <FreeTrialCta
                v-if="canTrial && freePlanLayout === 'top-right'"
                :single="brandHasSinglePlan"
                :show-single-price="showSinglePrice"
                :plan-id="subscription.plans.premium.monthly.id"
                variant="top-right"
                :page="brandConfig.alias"
                @initiatedCardlessTrial="getUser"
            ></FreeTrialCta>

            <FreeTrialCta
                v-if="canTrial && freePlanLayout === 'top-center'"
                :single="brandHasSinglePlan"
                :show-single-price="showSinglePrice"
                :plan-id="subscription.plans.premium.monthly.id"
                variant="top-center"
                :page="brandConfig.alias"
                @initiatedCardlessTrial="getUser"
            ></FreeTrialCta>

            <b-form-group v-if="freePlanLayout !== 'top-center'">
                <b-form-radio-group
                    id="btn-radios-2"
                    v-model="selectedSubcriptionCycle"
                    :options="subscriptionCycleOptions"
                    button-variant="outline-primary"
                    size="sm"
                    name="subscriptionCycle"
                    buttons
                ></b-form-radio-group>
            </b-form-group>
        </div>
        <div class="mb-3 text-center">
            <div class="container-fluid">
                <div v-if="subscriptionCycle !== 'lifetime'" class="row">
                    <div v-if="brandHasSinglePlan && showSinglePrice" :class="classes">
                        <div class="card p-3">
                            <div>
                                <div class="plan-desc mb-3">
                                    <p class="font-weight-bold mb-0">Single</p>
                                    <p class="text-muted f-13 mb-0">
                                        Unlock this
                                        {{
                                            brandConfig.alias !== 'worksheet'
                                                ? `${brandConfig.alias} set`
                                                : brandConfig.alias
                                        }}. Perfect for events.
                                        <br />
                                        <br />
                                        <br v-if="freePlanLayout !== 'plan-card' || brandConfig.alias !== 'flashcard'" />
                                    </p>
                                </div>

                                <h3 class="font-weight-bold d-inline">
                                    ${{ subscriptionAmount(subscription.plans.single.amount) }}
                                    <span class="text-muted f-13 ml-1 font-weight-normal">One-time payment</span>
                                </h3>
                                <button
                                    class="btn btn-block btn-primary mt-2"
                                    @click="subscribe(subscription.plans.single, PlanType.SINGLE)"
                                >
                                    Purchase
                                </button>
                                <p v-if="!user.user.trialed" class="mb-0 mt-1 f-12 text-center text-white">
                                    Not sure? Try a 30-day trial!
                                </p>
                            </div>
                            <hr />
                            <FeatureList title="WHAT'S INCLUDED" :features="brandConfig.planFeatures[PlanType.SINGLE]" />
                        </div>
                    </div>

                    <FreeTrialCta
                        v-if="canTrial && freePlanLayout === 'plan-card'"
                        :single="brandHasSinglePlan"
                        :show-single-price="showSinglePrice"
                        :plan-id="subscription.plans.premium.monthly.id"
                        variant="plan-card"
                        :page="brandConfig.alias"
                        @initiatedCardlessTrial="getUser"
                    ></FreeTrialCta>

                    <div :class="classes">
                        <div class="card p-3">
                            <div>
                                <div class="plan-desc mb-3">
                                    <p class="font-weight-bold mb-0">
                                        {{ planName('Basic') }}
                                    </p>

                                    <p v-if="brandConfig.alias === 'worksheet'" class="text-muted f-13">
                                        Make unlimited {{ brandConfig.name }}. Save worksheets as
                                        <strong class="text-dark font-weight-bold">private</strong>
                                        and skip the extras.
                                        <br />
                                        <br />
                                    </p>
                                    <p v-else class="text-muted f-13">
                                        Create unlimited
                                        <span class="text-dark font-weight-bold">{{ brandConfig.alias }} sets</span>
                                        .
                                        <span v-if="brandConfig.alias === 'bingo'">
                                            Great for activity directors, group leaders, and more.
                                            <br />
                                            <br />
                                        </span>
                                        <span v-if="brandConfig.alias === 'flashcard'">
                                            Great for regular vocabulary practice.
                                            <br />
                                            <br />
                                        </span>
                                    </p>
                                </div>

                                <h3 class="font-weight-bold d-inline">
                                    ${{ subscriptionAmount(subscription.plans.basic[brandType][subscriptionCycle].amount) }}
                                    <span class="text-muted f-13 ml-0 font-weight-normal">
                                        / {{ subscriptionCycle === 'monthly' ? 'month' : 'year' }}
                                        <span v-if="subscriptionCycle === 'yearly'" class="text-danger">(Save 15%)</span>
                                    </span>
                                </h3>
                                <button
                                    :disabled="
                                        userSubscribedTo('basic', subscriptionCycle, brandConfig.alias) &&
                                        !subscription.on_grace_period
                                    "
                                    class="btn btn-block mt-2 btn-primary"
                                    @click="
                                        userSubscribedTo('basic', subscriptionCycle, brandConfig.alias) &&
                                        subscription.on_grace_period
                                            ? resumeSubscription()
                                            : subscribe(
                                                  subscription.plans.basic[brandType][subscriptionCycle],
                                                  PlanType.BASIC,
                                              )
                                    "
                                >
                                    {{
                                        userSubscribedTo('basic', subscriptionCycle, brandConfig.alias) &&
                                        subscription.on_grace_period
                                            ? 'Resume Subscription'
                                            : 'Subscribe'
                                    }}
                                </button>
                                <div v-if="!user.user.trialed">
                                    <p
                                        v-if="!user.user.has_plan"
                                        class="mb-0 mt-1 f-12 text-center pointer domain-text-color"
                                        @click="
                                            freeTrial(subscription.plans.basic[brandType][subscriptionCycle], PlanType.BASIC)
                                        "
                                    >
                                        Not sure? Try a 30-day trial!
                                    </p>

                                    <p v-else class="mb-0 mt-1 f-12 text-center text-muted">Not sure? Try a 30-day trial!</p>
                                </div>
                            </div>
                            <hr />
                            <FeatureList title="WHAT'S INCLUDED" :features="brandConfig.planFeatures[PlanType.BASIC]" />
                        </div>
                    </div>

                    <div :class="classes">
                        <div class="card p-3">
                            <div>
                                <div class="plan-desc mb-3">
                                    <p class="font-weight-bold mb-0">
                                        {{ planName('Standard') }}
                                    </p>
                                    <p v-if="brandConfig.alias === 'worksheet'" class="text-muted f-13">
                                        Make unlimited worksheets and
                                        <span class="text-dark font-weight-bold">unlock all features and styles</span>
                                        . Keep it simple with worksheets only.
                                        <br />
                                    </p>
                                    <p v-if="brandConfig.alias === 'flashcard'" class="text-muted f-13">
                                        Make
                                        <span class="text-dark font-weight-bold">unlimited flashcards and bingo</span>
                                        . Great for interactive classroom activities.
                                        <br />
                                        <br />
                                    </p>
                                    <p v-if="brandConfig.alias === 'bingo'" class="text-muted f-13">
                                        Make
                                        <span class="text-dark font-weight-bold">unlimited bingo and flashcard set</span>
                                        . Great for interactive classroom activities.
                                        <br />
                                        <br />
                                    </p>
                                </div>

                                <h3 class="font-weight-bold d-inline">
                                    ${{
                                        subscriptionAmount(subscription.plans.standard[brandType][subscriptionCycle].amount)
                                    }}
                                    <span class="text-muted f-13 ml-0 font-weight-normal">
                                        / {{ subscriptionCycle === 'monthly' ? 'month' : 'year' }}
                                        <span v-if="subscriptionCycle === 'yearly'" class="text-danger">(Save 15%)</span>
                                    </span>
                                </h3>
                                <button
                                    v-if="
                                        userSubscribedTo('basic', subscriptionCycle) &&
                                        (user.user.has_plan || subscription.on_grace_period)
                                    "
                                    class="btn btn-block btn-primary mt-2"
                                    @click="upgrade(subscription.plans.basic[brandType][subscriptionCycle])"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else
                                    :disabled="
                                        userSubscribedTo('standard', subscriptionCycle) && !subscription.on_grace_period
                                    "
                                    class="btn btn-block mt-2 btn-primary"
                                    @click="
                                        userSubscribedTo('standard', subscriptionCycle) && subscription.on_grace_period
                                            ? resumeSubscription()
                                            : subscribe(
                                                  subscription.plans.standard[brandType][subscriptionCycle],
                                                  PlanType.STANDARD,
                                              )
                                    "
                                >
                                    {{
                                        userSubscribedTo('standard', subscriptionCycle) && subscription.on_grace_period
                                            ? 'Resume Subscription'
                                            : 'Subscribe'
                                    }}
                                </button>

                                <div v-if="!user.user.trialed">
                                    <p
                                        v-if="!user.user.has_plan"
                                        class="mb-0 mt-1 f-12 domain-text-color text-center pointer"
                                        @click="
                                            freeTrial(
                                                subscription.plans.standard[brandType][subscriptionCycle],
                                                PlanType.STANDARD,
                                            )
                                        "
                                    >
                                        Not sure? Try a 30-day trial!
                                    </p>

                                    <p v-else class="mb-0 mt-1 f-12 text-center text-muted">Not sure? Try a 30-day trial!</p>
                                </div>
                            </div>
                            <hr />
                            <FeatureList title="WHAT'S INCLUDED" :features="brandConfig.planFeatures[PlanType.STANDARD]" />
                        </div>
                    </div>

                    <div :class="classes">
                        <div class="card p-3 position-relative">
                            <span
                                class="position-absolute d-flex justify-content-center"
                                style="top: -10px; left: 0; right: 0"
                            >
                                <span class="badge badge-pill badge-danger py-1 px-3">Educator Value Pack</span>
                            </span>
                            <div>
                                <div class="plan-desc mb-3">
                                    <p class="font-weight-bold mb-0">Premium</p>
                                    <p v-if="brandConfig.alias === 'worksheet'" class="text-muted f-13">
                                        Create unlimited worksheets, flashcards, bingo, crosswords, word searches, and more.
                                        <span class="text-dark font-weight-bold">Everything</span>
                                        is included.
                                    </p>

                                    <p v-if="brandConfig.alias === 'bingo'" class="text-muted f-13">
                                        Create unlimited bingo, flashcards, worksheets, crosswords, word searches, and more.
                                        <span class="text-dark font-weight-bold">Everything</span>
                                        is included.
                                    </p>

                                    <p v-if="brandConfig.alias === 'flashcard'" class="text-muted f-13">
                                        Create unlimited flashcards, bingo, worksheets, crosswords, word searches, and more.
                                        <span class="text-dark font-weight-bold">Everything</span>
                                        is included.
                                    </p>
                                </div>

                                <h3 class="font-weight-bold d-inline">
                                    ${{ subscriptionAmount(subscription.plans.premium[subscriptionCycle].amount) }}
                                    <span class="text-muted f-13 ml-0 font-weight-normal">
                                        / {{ subscriptionCycle === 'monthly' ? 'month' : 'year' }}
                                        <span v-if="subscriptionCycle === 'yearly'" class="text-danger">(Save 15%)</span>
                                    </span>
                                </h3>

                                <button
                                    v-if="
                                        !userSubscribedTo('premium', subscriptionCycle) &&
                                        user.user.subscription_plan !== 'Free Trial' &&
                                        user.user.has_plan
                                    "
                                    class="btn btn-block btn-primary mt-2"
                                    @click="upgrade(subscription.plans.premium[subscriptionCycle])"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else
                                    :disabled="
                                        userSubscribedTo('premium', subscriptionCycle) && !subscription.on_grace_period
                                    "
                                    class="btn btn-block mt-2 btn-primary"
                                    @click="
                                        userSubscribedTo('premium', subscriptionCycle) && subscription.on_grace_period
                                            ? resumeSubscription()
                                            : subscribe(subscription.plans.premium[subscriptionCycle], PlanType.PREMIUM)
                                    "
                                >
                                    {{
                                        userSubscribedTo('premium', subscriptionCycle) && subscription.on_grace_period
                                            ? 'Resume Subscription'
                                            : 'Subscribe'
                                    }}
                                </button>

                                <p
                                    v-if="!user.user.trialed"
                                    class="mb-0 mt-1 f-12 domain-text-color text-center pointer"
                                    @click="freeTrial(subscription.plans.premium[subscriptionCycle], PlanType.PREMIUM)"
                                >
                                    Not sure? Try a 30-day trial!
                                </p>
                            </div>
                            <hr />
                            <FeatureList title="WHAT'S INCLUDED" :features="brandConfig.planFeatures[PlanType.PREMIUM]" />
                        </div>
                    </div>
                </div>

                <div v-if="subscriptionCycle === 'lifetime'" class="row justify-content-center pt-4">
                    <div :class="brandHasSinglePlan ? 'col-lg-9' : 'col-lg-6'">
                        <h2 class="font-weight-bold lifetime-title">Lifetime Membership</h2>
                        <div class="rounded-lg bg-primary text-white p-3">
                            <h5 class="text-center font-weight-bolder">The Ultimate Teacher Package</h5>
                            <p class="mb-4">One-time payment. Incredibly long-lasting value.</p>
                            <div class="f-14 text-left mb-3 mx-3">
                                <div class="mb-2 d-flex">
                                    <div class="flex-shrink-0 mr-2">
                                        <svg height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    Pay once, use forever. Membership never expires.
                                </div>
                                <div class="mb-2 d-flex">
                                    <div class="flex-shrink-0 mr-2">
                                        <svg height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    Ideal for career teachers and year-end purchasing.
                                </div>
                                <div class="mb-2 d-flex">
                                    <div class="flex-shrink-0 mr-2">
                                        <svg height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    Utilize every single feature on every single sister site.
                                </div>
                                <div class="mb-2 d-flex">
                                    <div class="flex-shrink-0 mr-2">
                                        <svg height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    Create unlimited, ultra-premium worksheets, crosswords, word searches, bingo, and
                                    flashcards for years to come — without ever paying again.
                                </div>
                            </div>
                            <img width="250" :src="'/images/lifetime.svg'" alt="lifetime" />
                        </div>
                    </div>
                    <div class="col-lg-3 text-left">
                        <div class="card p-3 position-relative">
                            <span
                                class="position-absolute d-flex justify-content-center"
                                style="top: -10px; left: 0; right: 0"
                            >
                                <span class="badge badge-pill badge-danger py-1 px-3">Educator Value Pack</span>
                            </span>
                            <div>
                                <div class="plan-desc mb-3">
                                    <p class="font-weight-bold mb-0">Premium</p>
                                    <p v-if="brandConfig.alias === 'worksheet'" class="text-muted f-13">
                                        Create unlimited worksheets, flashcards, bingo, crosswords, word searches, and more.
                                        <span class="text-dark font-weight-bold">Everything</span>
                                        is included.
                                    </p>

                                    <p v-if="brandConfig.alias === 'bingo'" class="text-muted f-13">
                                        Create unlimited bingo, flashcards, worksheets, crosswords, word searches, and more.
                                        <span class="text-dark font-weight-bold">Everything</span>
                                        is included.
                                    </p>

                                    <p v-if="brandConfig.alias === 'flashcard'" class="text-muted f-13">
                                        Create unlimited flashcards, bingo, worksheets, crosswords, word searches, and more.
                                        <span class="text-dark font-weight-bold">Everything</span>
                                        is included.
                                    </p>
                                </div>

                                <h3 class="font-weight-bold d-inline">
                                    ${{ subscriptionAmount(subscription.plans.lifetime.amount) }}
                                    <span class="text-muted f-13 ml-2 font-weight-normal">One-Time Payment</span>
                                </h3>
                                <button
                                    class="btn btn-block btn-primary mt-2"
                                    @click="subscribe(subscription.plans.lifetime, PlanType.LIFETIME)"
                                >
                                    Purchase
                                </button>
                            </div>
                            <hr />
                            <FeatureList title="WHAT'S INCLUDED" :features="brandConfig.planFeatures[PlanType.LIFETIME]" />
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <FreeTrialCta
                        v-if="canTrial && freePlanLayout === 'default'"
                        :single="brandHasSinglePlan"
                        :show-single-price="showSinglePrice"
                        :plan-id="subscription.plans.premium.monthly.id"
                        @initiatedCardlessTrial="getUser"
                    ></FreeTrialCta>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { PlanType } from '../../objects/Plan'

import FeatureList from '../../stories/components/data/lists/FeatureList.vue'
import FreeTrialCta from '../FreeTrialCta.vue'

import WithCheckout from '../../mixins/WithCheckout'
import EditPayWall from '../../mixins/EditPayWall'

interface Data {
    PlanType: any
    selectedSubcriptionCycle?: string
}

export default defineComponent({
    name: 'PlanSelector',
    components: {
        FeatureList,
        FreeTrialCta,
    },
    mixins: [WithCheckout, EditPayWall],
    props: {
        isEditor: {
            type: Boolean,
            default: false,
        },
    },
    data(): Data {
        return {
            PlanType,
            selectedSubcriptionCycle: undefined,
        }
    },
    computed: {
        ...mapGetters({
            neverHadTrial: 'user/neverHadTrial',
            isWorksheet: 'document/isWorksheet',
        }),
        brandType() {
            if (this.page === 'account') {
                return 'worksheet'
            }
            if (this.page === 'flashcard') {
                return 'flashcards'
            }
            return this.page
        },
        classes() {
            return `${this.canTrial && this.freePlanLayout === 'plan-card' ? 'col' : !this.brandHasSinglePlan || !this.showSinglePrice ? 'col-lg-4' : 'col-lg-3'} text-left mb-3 col-12 col-md-6`
        },
        canTrial() {
            return this.isWorksheet && this.neverHadTrial
        },
    },
    watch: {
        selectedSubcriptionCycle() {
            if (!this.selectedSubcriptionCycle) {
                return
            }

            this.setSubscriptionCycle(this.selectedSubcriptionCycle)
        },
    },
    mounted() {
        try {
            if (!this.oneClickUpgrade.plan) {
                this.selectedSubcriptionCycle = this.subscriptionCycleOptions[0].value
                this.$emit('active-step', 'subscription_type')
                // if (this.plan.name == 'Single Document' && !this.document.id) {
                //     return
                // }
                // TODO: Used in the payment details step
                // this.$refs.card.innerHTML = ''
                return
            }

            this.setSubscriptionCycle(this.oneClickUpgrade.subscriptionCycle)
        } catch (error) {
            console.error(error)
        }
    },
    methods: {
        planName(name: string) {
            if (this.brandConfig.alias === 'worksheet') {
                return name
            }

            if (this.brandConfig.alias === 'bingo') {
                if (name === 'Basic') {
                    return 'Unlimited Bingo'
                }

                if (name === 'Standard') {
                    return 'Bingo + Flashcards'
                }
            }

            if (this.brandConfig.alias === 'flashcard') {
                if (name === 'Basic') {
                    return 'Unlimited Flashcards'
                }

                if (name === 'Standard') {
                    return 'Flashcards + Bingo'
                }
            }
        },
        userSubscribedTo(plan: any, cycle: string | null = null, brand: string | null = null) {
            if (!this.user.user.has_plan) {
                return false
            }

            let sub = this.user.user.subscription_plan
            let subcycle = this.subscription.stripe_cycle

            if (brand === 'bingo' || brand === 'flashcard') {
                return (
                    ((plan === 'basic' && (sub === 'Unlimited Bingo' || sub === 'Unlimited Flashcards')) ||
                        (plan === 'standard' && sub === 'Unlimited Bingo and Flashcards') ||
                        (plan === 'premium' && sub === 'Premium')) &&
                    subcycle === cycle
                )
            }

            if (brand === 'worksheet') {
                return (
                    ((plan === 'basic' && sub === 'Basic') ||
                        (plan === 'standard' && sub === 'Standard') ||
                        (plan === 'premium' && sub === 'Premium')) &&
                    subcycle === cycle
                )
            }

            return false
        },
        resumeSubscription() {
            this.$store.dispatch('subscription/resumeSubscription')
            this.$bvModal.hide('checkout-modal')
        },
        async freeTrial(plan: any, planType: PlanType) {
            await this.$store.dispatch('cards/obtainIntent')

            this.$emit('active-step', 'payment')
            this.setFreeTrialPeriod(30)
            this.setSelectedPlan(plan)
            this.setSelectedPlanType(planType)
            this.logChoosePlan(this.plan?.interval, 'free trial', this.planLabel?.toLowerCase(), this.page)
            this.$eventApi.create_event(
                'free-trial-' + plan.interval + '-' + plan.id,
                'days ' + this.freeTrialPeriod + ' | price ' + plan.amount,
            )
        },
        upgrade(plan: any) {
            this.$emit('active-step', 'upgrade')
            this.setSelectedPlan(plan)
            this.logChoosePlan(this.plan?.interval, 'buy now', this.planLabel?.toLowerCase(), this.page)
            this.$eventApi.create_event(
                'upgrade-plan-' + plan.interval + '-' + plan.id,
                `${plan.name} | Price: ${plan.amount}`,
            )
        },
    },
})
</script>
