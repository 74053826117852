<template>
    <div :class="noticeClass">
        <div v-if="icon" class="flag">
            <IconLoader :icon="icon" :size="iconSize" />
        </div>
        <div :class="contentClass">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    iconOptions,
    iconSizeOptions,
    colorTypeOptions,
    fontWeightOptions,
    textAlignOptions,
} from '../../mocks/StorybookOptions.mock'
import IconLoader from '../icons/IconLoader.vue'

export default defineComponent({
    name: 'Notice',
    components: {
        IconLoader,
    },
    props: {
        rounded: {
            type: Boolean,
            default: true,
        },
        icon: {
            type: String,
            default: null,
            validator: (value: string) => iconOptions.includes(value),
        },
        iconSize: {
            type: String,
            default: 'sm',
            validator: (value: string) => iconSizeOptions.includes(value),
        },
        variant: {
            type: String,
            default: 'primary',
            validator: (value: string) => colorTypeOptions.includes(value),
        },
        fontWeight: {
            type: String,
            default: 'bold',
            validator: (value: string) => fontWeightOptions.includes(value),
        },
        textAlign: {
            type: String,
            default: 'center',
            validator: (value: string) => textAlignOptions.includes(value),
        },
        light: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        noticeClass(): object {
            return {
                'p-3': true,
                'f-13': true,
                'mb-3': true,
                'position-relative': true,
                rounded: this.rounded,
                [`font-weight-${this.fontWeight}`]: !!this.fontWeight,
                [`text-${this.textAlign}`]: !!this.textAlign,
                ...this.variantClasses,
            }
        },
        contentClass(): object {
            return {
                'pl-4': !!this.icon,
            }
        },
        variantClasses(): object {
            // The light option is currently only for primary variant.
            if (this.variant === 'primary' && this.light) {
                return {
                    [`bg-${this.variant}-50`]: !!this.variant,
                    [`text-${this.variant}-900`]: !!this.variant,
                    [`border border-${this.variant}-100`]: !!this.variant,
                }
            }

            return {
                [`notice-${this.variant}`]: !!this.variant,
                [`bg-${this.variant}`]: !!this.variant,
                [`border border-${this.variant}`]: !!this.variant,
            }
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.notice-primary,
.notice-secondary,
.notice-success,
.notice-danger,
.notice-info,
.notice-dark {
    color: $white;
}

.flag {
    position: absolute;
    width: 30px;
    left: 15px;
    top: 10px;
}
</style>
