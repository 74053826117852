<template>
    <div>
        <p class="f-12 mb-2" :class="titleClass">{{ title }}</p>
        <div v-for="(feature, index) in features" :key="index" class="d-flex item-center">
            <IconLoader
                :icon="feature.isEnabled ? enabledIcon : disabledIcon"
                :type="feature.isEnabled ? enabledIconColorType : disabledIconColorType"
                size="sm"
            />
            <span class="f-14 text-muted ml-1">{{ feature.text }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { fontWeightOptions, iconOptions, colorTypeOptions } from '../../../mocks/StorybookOptions.mock'
import Feature from '../../../types/Feature'
import IconLoader from '../../icons/IconLoader.vue'

export default defineComponent({
    name: 'FeatureList',
    components: {
        IconLoader,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        titleFontWeight: {
            type: String,
            default: 'bold',
            validator: (value: string) => fontWeightOptions.includes(value),
        },
        enabledIcon: {
            type: String,
            default: 'Check',
            validator: (value: string) => iconOptions.includes(value),
        },
        enabledIconColorType: {
            type: String,
            default: 'success',
            validator: (value: string) => colorTypeOptions.includes(value),
        },
        disabledIcon: {
            type: String,
            default: 'Cross',
            validator: (value: string) => iconOptions.includes(value),
        },
        disabledIconColorType: {
            type: String,
            default: 'danger',
            validator: (value: string) => colorTypeOptions.includes(value),
        },
        features: {
            type: Array as PropType<Feature[]>,
            default: () => [],
        },
    },

    computed: {
        titleClass(): string {
            return `font-weight-${this.titleFontWeight}`
        },
    },
})
</script>
